
















































































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import ProjectFile from "@/models/ProjectFile"
import { TypedComponentProps } from "@/vue-props-types"
import FileIcons from "./FileIcons.vue"
import { NewFileState } from "./FileUpload.vue"

interface State {
  isLoading: boolean
  folderItems: Array<ProjectFile>
}

interface Props {
  value: Array<ProjectFile>
  isActive: boolean
  filePath: string | null
  uploadFiles: Array<{ state: NewFileState; file: ProjectFile }>
}

const propsOptions = {
  value: {
    type: Array,
  },
  isActive: {
    type: Boolean,
  },
  filePath: {
    type: String,
  },
  uploadFiles: {
    type: Array as PropType<Array<{ state: NewFileState; file: ProjectFile }>>,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      FileIcons,
      I18nFormattedMessage,
    },
    setup(props, { emit }) {
      const state = reactive<State>({
        isLoading: false,
        folderItems: [],
      })
      const { getFiles, getContext } = teamsContextContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const selectedItems = computed({
        get: () => {
          return props.value
        },
        set: newValue => {
          emit("input", newValue)
        },
      })

      const onSelectItem = (selectItem: ProjectFile) => {
        if (selectItem.isFolder) {
          emit("changeFolder", selectItem.parentPath + selectItem.name)
        } else {
          if (selectedItems.value?.some(s => s.webUrl === selectItem.webUrl)) {
            selectedItems.value = selectedItems.value.filter(
              s => s.webUrl !== selectItem.webUrl
            )
          } else {
            selectedItems.value?.push(selectItem)
          }
        }
      }

      const onSelectPath = (jumpToPath: string) => {
        emit("changeFolder", jumpToPath ? jumpToPath : "/")
      }

      const paths = computed(() => {
        let rets = [{ displayName: "ドキュメント", path: "" }]
        rets =
          props.filePath
            ?.split("/")
            .filter(f => !!f)
            .reduce((r, f, i) => {
              r.push({
                displayName: f,
                path: r[i].path + f + "/",
              })
              return r
            }, rets) ?? []
        return rets
      })

      const currentFiles = computed(() => {
        return state.folderItems.filter(f => {
          const file = props.uploadFiles?.find(u => u.file.webUrl === f.webUrl)
          return file?.state !== "delete" && file?.state !== "new"
        })
      })

      const renewFolder = async (path: string | null) => {
        if (path) {
          const context = await getContext()
          const project = await getProjectAsync(context.entityId)
          state.isLoading = true
          const ret = await getFiles(project.siteId, path)
          state.folderItems.splice(0, state.folderItems.length)
          state.folderItems.push(...ret)
          state.isLoading = false
        } else {
          state.folderItems = []
        }
      }

      watch(
        () => [props.filePath, props.isActive],
        async ([path, isActive]) => {
          if (isActive) await renewFolder(path as string)
        }
      )

      onMounted(async () => {
        if (props.filePath) {
          await renewFolder(props.filePath)
        }
      })

      return {
        state,
        currentFiles,
        paths,
        selectedItems,
        onSelectPath,
        onSelectItem,
      }
    },
  }
)
